{
  "-": "-",
  "hello world": "hola mundo",

  "Value is required": "El campo es obligatorio",
  "Value is not a valid email address": "El valor ingresado no corresponde a una dirección de correo válida",
  "The value must be equal to the Email value": "El valor ingresado debe ser igual al valor ingresado en el campo Email",
  "The value must be equal to the Password value": "El valor ingresado debe ser igual al valor ingresado en el campo Contraseña",
  "This field should be at least 8 long": "El valor ingresado debe contener al menos 8 caracteres",
  "This field should be at least 8 characters long": "El valor ingresado debe contener al menos 8 caracteres",
  "Nombre": "Nombre",
  "Email": "Email",
  "Confirmar email": "Confirmar email",
  "Contraseña": "Contraseña",
  "Confirmar contraseña": "Confirmar contraseña",
  "Creá tu cuenta": "Creá tu cuenta",
  "Ya tengo cuenta": "Ya tengo cuenta",
  "Registrarme": "Registrarme",
  
  "login.error": "No se pudo iniciar sesión. Verifica tu correo electrónico y contraseña, e inténtalo de nuevo",

  "order_status_open": "Abierto",
  "order_status_closed": "Cerrado",
  "order_status_cancelled": "Cancelado",
  "order_status_paid": "Confirmado",
  "order_status_pending": "En proceso",
  "order_status_abandoned": "Abandonado",
  "order_status_authorized": "Autorizado",
  "order_status_refunded": "Reintegrado",
  "order_status_voided": "Rechazado",
  "order_status_unpacked": "No Empaquetado",
  "order_status_packed": "Empaquetado",
  "order_status_shipped": "Enviado",
  "order_status_unshipped": "Empaquetado",
  "order_status_ship_unpacked": "No Empaquetado",
  "order_status_ship_packed": "Empaquetado",
  "order_status_ship_shipped": "Enviado",
  "order_status_ship_unshipped": "Empaquetado",
  "order_status_pickup_unpacked": "No Empaquetado",
  "order_status_pickup_packed": "Empaquetado",
  "order_status_pickup_shipped": "Retirado",
  "order_status_pickup_unshipped": "No Retirado",
  
  "Locale": "Local",

  "month": "mes",
  "cardNumber is empty.": "El número de tarjeta está vacío.",
  "cardNumber should be a number.": "El número de tarjeta debe ser un número válido.",
  "cardNumber should be of length between '8' and '19'.": "El número de tarjeta debe contener entre 8 y 19 caracteres.",
  "expirationMonth is empty.": "El mes de vencimiento está vacío.",
  "expirationMonth should be a number.": "El mes de vencimiento debe ser un número válido.",
  "expirationMonth should be a value from 1 to 12.": "El mes de vencimiento debe ser un número entre 1 y 12.",
  "expirationYear is empty.": "El año de vencimiento está vacío.",
  "expirationYear should be of length '2'.": "El año de vencimiento debe contener exactamente 2 caracteres.",
  "expirationYear should be a number.": "El año de vencimiento debe ser un número válido.",
  "expirationYear value should be greater or equal than 2022.": "El año de vencimiento debería ser mayor o igual que 2022.",
  "expirationYear value should be greater or equal than 2023.": "El año de vencimiento debería ser mayor o igual que 2023.",
  "securityCode is empty.": "El código de seguridad está vacío.",
  "securityCode should be of length '3' or '4'.": "El código de seguridad debe contener entre 3 y 4 caracteres.",
  "securityCode should be a number.": "El código de seguridad debe ser un número válido.",

  "approved": "aprobado",
  "accredited": "acreditado",
  "in_process": "en proceso",
  "offline_process": "proceso automático",
  "rejected": "rechazado",
  "cc_rejected_high_risk": "alto riesgo",

  "customer_category": "Categoría de Cliente",
  "product_category": "Categoría de Producto",
  "product": "Producto",
  "customer": "Cliente",
  "promotion": "Promoción",
  "plural_customer_category": "Categorías de Cliente",
  "plural_product_category": "Categorías de Producto",
  "plural_product": "Productos",
  "plural_customer": "Clientes",
  "plural_promotion": "Promociones",

  "normal": "normal",
  "Normal": "Normal",
  "preorder": "preventa",
  "Preorder": "Preventa",
  "delayed": "Con tiempo de fabricación",
  "Delayed": "Con tiempo de fabricación",
  "personalizado": "personalizado",
  "Personalizado": "Personalizado",

  "Básico": "Básico",
  "Basic": "Básico",
  "básico": "Básico",
  "basic": "Básico",
  "Profesional": "Profesional",
  "Professional": "Profesional",
  "profesional": "Profesional",
  "professional": "Profesional",
  "Empresarial": "Empresarial",
  "Business": "Empresarial",
  "empresarial": "Empresarial",
  "business": "Empresarial",
  "Mas allá": "Mas allá",
  "Beyond": "Mas allá",
  "mas allá": "Mas allá",
  "beyond": "Mas allá",

  "file": "Archivar",
  "cancel": "Cancelar",
  "pay": "Pagar",
  "pack": "Empaquetar",
  "ship": "Enviar",
  "hide": "Ocultar",
  "show": "Mostrar",
  "delete": "Eliminar",
  "activate": "Activar",
  "deactivate": "Desactivar",
  "hideInstallments": "Ocultar cuotas",
  "showInstallments": "Mostrar cuotas"
}
